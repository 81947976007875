.modal_achievments h1 {
    margin-top: 50px;
}

.modal_achievments .achievments_list_order {
    width: 100%;
    max-width: 1290px;

    height: 100%;
    max-height: 550px;

    row-gap: 20px;
    column-gap: 15px;

    display: grid;
    align-items: flex-start;
    grid-template-columns: 1fr 1fr 1fr 1fr;

    overflow-y: auto;
    scrollbar-width: thin;
    scrollbar-color: #90ABFF #44444415;
    
    background-color: #f4f4f4;
    box-shadow: 0 0 14px 14px #f4f4f4;
    
    margin: 20px auto 0;
    padding: 25px 40px;
}

.modal_achievments .achievments_list_order .ctn_onglet_achievment {
    position: relative;
}

.modal_achievments .achievments_list_order .onglet_achievments {
    width: 100%;
    height: 100%;
    padding: 8px;

    cursor: pointer;
    transition: .2s;

    display: flex;
    row-gap: 8px;
    flex-direction: column;

    box-shadow: 0 2px 6px #44444414;
    background-color: #fff;
    border-radius: 4px;
}

.modal_achievments .achievments_list_order .onglet_achievments:hover {
    opacity: .7;
}

.modal_achievments .achievments_list_order .onglet_achievments .preview {
    width: 100%;
    height: 160px;
    display: flex;
    justify-content: center;
    background-color: #0467ab33;
    border-radius: 4px 4px 0 0;
}

.modal_achievments .achievments_list_order .onglet_achievments img {
    width: 100%;
    height: 100%;
    max-width: 150px;
    object-fit: contain;
}

.modal_achievments .achievments_list_order span {
    width: 100%;
    max-height: 40px;

    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;

    overflow: hidden;
    text-align: left;
    text-overflow: ellipsis;
    align-items: flex-start;

    color: #0467ab;
    padding: 0 5px;

    font-family: 'Manrope', sans-serif;
    font-weight: 600;
    font-size: 14px;   
}

.modal_achievments .achievments_list_order .btn_next,
.modal_achievments .achievments_list_order .btn_prev {
    transform: translateY(-50%);
    position: absolute;
    z-index: 10;
    top: 90px;

    width: 30px;
    height: 30px;
    
    border-radius: 15px;
    font-size: 16px;
    opacity: 0;

    box-shadow: 0 0 0 2px #fff;
    background-color: #90ABFF;
    transition: all .2s;
    color: #fff;
}

.modal_achievments .ctn_onglet_achievment:hover .btn_next,
.modal_achievments .ctn_onglet_achievment:hover .btn_prev {
    opacity: .95;
}

.modal_achievments .achievments_list_order .btn_next:hover,
.modal_achievments .achievments_list_order .btn_prev:hover {
    opacity: .6;
}

.modal_achievments .achievments_list_order .btn_next {
    right: 12px;
}

.modal_achievments .achievments_list_order .btn_prev {
    left: 12px;
}