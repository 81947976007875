.category_selector,
.product_selector,
.service_selector,
.achievment_selector {
    height: 100%;
    max-height: 84%;
    position: relative;

    row-gap: 8px;
    display: flex;
    flex-direction: column;
}

.category_selector h2,
.product_selector h2,
.service_selector h2,
.achievment_selector h2 {
    height: 30px;
    color: #90ABFF;
    text-shadow: 0 0 1px #00000020;
    
    font-family: 'Glory', sans-serif;
    font-weight: 600;
    font-size: 22px;
}

.category_selector ul,
.product_selector ul,
.service_selector ul,
.achievment_selector ul {
    width: 100%;
    max-height: calc(100% - 38px);
    padding: 15px 0;

    gap: 12px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;

    overflow-y: auto;
    scrollbar-width: thin;
    scrollbar-color: #90ABFF #44444415;
}

.category_selector ul li,
.product_selector ul li,
.service_selector ul li,
.achievment_selector ul li {
    width: 100%;
    height: 58px;

    display: flex;
    align-items: center;
    justify-content: space-between;

    box-shadow: 0 0 4px #14141405;
    border: solid 1px #14141415;
    padding: 0 14px;
}

.category_selector ul .li_blue,
.product_selector ul .li_blue,
.service_selector ul .li_blue,
.achievment_selector ul .li_blue {
    background-color: #90ABFF55;
}

.category_selector ul .li_yellow,
.product_selector ul .li_yellow,
.service_selector ul .li_yellow,
.achievment_selector ul .li_yellow {
    background-color: #F59B4840;
}

.product_selector ul .li_red,
.service_selector ul .li_red,
.achievment_selector ul .li_red {
    background-color: #d4121730;
}

.category_selector ul .path,
.product_selector ul .path,
.service_selector ul .path,
.achievment_selector ul .path {
    width: calc(100% - 54px);
    height: 100%;

    display: flex;
    align-items: center;

    font-family: 'Glory', sans-serif;
    font-weight: 500;
    font-size: 16px;
    
    color: #141414cc;
    text-align: left;
}

.category_selector ul .check_box,
.product_selector ul .check_box,
.service_selector ul .check_box,
.achievment_selector ul .check_box {
    min-width: 26px;
    min-height: 26px;

    display: flex;
    background-color: #fff;
    border: solid 1px #80808088;
}

.category_selector ul .check_box i,
.product_selector ul .check_box i,
.service_selector ul .check_box i,
.achievment_selector ul .check_box i {
    padding: 0 0 0 3px;
    font-size: 18px;
    opacity: .7;
}

.category_selector li .btn_delete,
.product_selector li .btn_delete,
.service_selector li .btn_delete,
.achievment_selector li .btn_delete {
    width: 32px;
    height: 32px;
    
    display: flex;
    align-items: center;
    justify-content: center;

    border-radius: 4px;
    background-color: #fff;
    border: solid 1px #14141425;

    transition: all .3s;
    padding: 4px;
    opacity: 0;
}

.category_selector li:hover .btn_delete,
.product_selector li:hover .btn_delete,
.service_selector li:hover .btn_delete,
.achievment_selector li:hover .btn_delete {
    opacity: 1;
}

.category_selector li:hover .btn_delete:hover,
.product_selector li:hover .btn_delete:hover,
.service_selector li:hover .btn_delete:hover,
.achievment_selector li:hover .btn_delete:hover {
    opacity: .5;
}

.category_selector li img,
.product_selector li img,
.service_selector li img,
.achievment_selector li img {
    width: 95%;
    height: 95%;
    object-fit: contain;
}

.category_selector .void_list,
.product_selector .void_list,
.service_selector .void_list,
.achievment_selector .void_list {
    position: relative;
}

.category_selector .void_list .no_value,
.product_selector .void_list .no_value,
.service_selector .void_list .no_value,
.achievment_selector .void_list .no_value {
    transform: translate(-50%, -50%);
    position: absolute;
    left: 50%;
    top: 50%;

    width: 100%;
    display: flex;
    justify-content: center;

    font-family: 'Dosis', sans-serif;
    font-weight: 500;
    font-size: 60px;

    color: #90ABFF;
    opacity: .3;
}