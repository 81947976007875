
/* ----------------------------------------------------------- */
/* ------------- Slider Categories & Produits ---------------- */
/* ----------------------------------------------------------- */

.sect_verif_categories .slider,
.sect_verif_products .slider {
    width: 100%;
    max-width: 900px;
    height: 400px;
    row-gap: 35px;

    display: flex;
    justify-content: center;
    flex-direction: column;

    box-shadow: 0 0 6px #00000015;
    background-color: white;

    border: solid 1px #00000005;
    border-radius: 5px;

    padding: 35px 40px;
    position: relative;
    overflow: hidden;
}

.sect_verif_categories .slider .content,
.sect_verif_products .slider .content {
    width: 100%;
    height: 100%;

    display: flex;
    column-gap: 40px;

    align-items: center;
    justify-content: space-between;

    animation: slider 7s 0s infinite linear;
}

@keyframes slider {
    0% {
        opacity: 0;
    }

    5% {
        opacity: 0;
    }

    19.2%,
    80.8% {
        opacity: 1;
    }

    95% {
        opacity: 0;
    }

    100% {
        opacity: 0;
    }
}

.sect_verif_categories .slider .descrip,
.sect_verif_products .slider .descrip {
    width: calc(100% - 350px);
    height: 100%;

    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.sect_verif_categories .slider h2,
.sect_verif_products .slider h2 {
    color: #0467abcc;
    font-family: 'Roboto', sans-serif;
    font-weight: 700;
    font-size: 28px;
}

.sect_verif_categories .slider p,
.sect_verif_products .slider p {
    width: 100%;
    max-width: 500px;

    height: 100%;
    max-height: 165px;

    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 9;

    margin-bottom: 15px;
    padding-left: 4px;
    color: #444444;

    overflow: hidden;
    text-overflow: ellipsis;

    font-family: 'Roboto', sans-serif;
    font-weight: 400;
    font-size: 15px;
}

.sect_verif_categories .slider .btn_descr,
.sect_verif_products .slider .btn_descr {
    height: 34px;

    padding: 8px 20px;
    color: #0467AB;
    transition: all .2s;

    display: flex;
    align-items: center;
    align-self: flex-start;
    justify-content: center;

    border-radius: 3px;
    border: solid 1px #0467AB;

    font-family: 'Roboto', sans-serif;
    font-weight: 400;
    font-size: 16px;
}

.sect_verif_categories .slider .btn_descr:hover,
.sect_verif_products .slider .btn_descr:hover {
    background-color: #0467AB;
    color: white;
    opacity: .7;
}

.sect_verif_categories .slider_categories .img_category,
.sect_verif_products .slider_categories .img_category {
    width: 300px;
    height: 260px;
    display: flex;
    align-items: center;
}

.sect_verif_categories .slider_categories img,
.sect_verif_products .slider_categories img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.sect_verif_categories .slider .dots,
.sect_verif_products .slider .dots {
    width: 100%;
    height: 10px;

    display: flex;
    column-gap: 11px;
    flex-direction: row;
    justify-content: center;
}

.sect_verif_categories .slider .dot,
.sect_verif_products .slider .dot {
    width: 8px;
    height: 7px;

    display: flex;
    border-radius: 4px;

    background-color: #80808033;
    box-shadow: 0 0 2px #00000050;
}

.sect_verif_categories .slider .dot_actif,
.sect_verif_products .slider .dot_actif {
    width: 50px;
}

.sect_verif_categories .slider .dot_actif::before,
.sect_verif_products .slider .dot_actif::before {
    width: 8px;
    height: 7px;

    content: '';
    display: flex;

    animation: dotAnime 7s 0s infinite linear;

    background-color: #0467abb0;
    border-radius: 4px;
}

@keyframes dotAnime {
    0% {
        width: 7px;
    }

    100% {
        width: 50px;
    }
}

/* ----------------------------------------------------------- */
/* -------------------- Slider services ---------------------- */
/* ----------------------------------------------------------- */

.slider_services {
    width: 580px;
    height: 384px;
    max-height: 384px;

    display: flex;
    row-gap: 20px;
    flex-direction: column;

    margin-bottom: 14px;
    position: relative;
}

.slider_services .border_view {
    padding: 16px;
    background-color: #fff;
    box-shadow: 0 0 4px #808080a1;
}

.slider_services .view {
    width: 100%;
    height: 320px;
    overflow: hidden;
    background-color: #0467ab15;
    box-shadow: inset 0 0 4px #808080a1;
}

.slider_services .view img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.slider_services .arrow {
    width: 30px;
    height: 50px;

    display: flex;
    justify-content: center;

    background-color: #808080d0;
    color: #fff;

    transition: all .2s;
    padding: 3px;

    font-family: 'Dosis', sans-serif;
    font-weight: 700;
    font-size: 30px;

    transform: translateY(-50%);
    position: absolute;
    top: 45%;
}

.slider_services .arrow:hover {
    background-color: #0467ab88;
}

.slider_services .arrow_left {
    left: -30px;
    padding-left: 8px;
    border-radius: 60px 0 0 60px;
}

.slider_services .arrow_right {
    right: -30px;
    padding-right: 8px;
    border-radius: 0 60px 60px 0;
}

.slider_services .dots {
    display: flex;
    column-gap: 12px;
    justify-content: center;
}

.slider_services .dot {
    width: 12px;
    height: 12px;

    display: flex;
    border-radius: 6px;
    background-color: #fff;
    box-shadow: 0 0 2px #808080a1;
}

.slider_services .dot_actif {
    background-color: #0467ab88;
}