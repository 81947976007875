.modal .benefits_features_files {
    display: flex;
    column-gap: 80px;
}

.modal .benefits_features_files h2 {
    font-family: 'Jaldi', sans-serif;
    font-weight: 600;
    font-size: 16px;
    color: #444;
}

.modal .benefits_features_files .list {
    width: calc(25% - 40px);
    flex-direction: column;
    display: flex;
    row-gap: 4px;
}

.modal .benefits_features_files .list ul {
    width: 100%;
    height: 100%;
    max-height: 300px;

    overflow-y: auto;
    scrollbar-width: thin;
    scrollbar-color: #90ABFF #44444415;

    display: flex;
    row-gap: 12px;
    flex-direction: column;
}

.modal .benefits_features_files .list .panel_list {
    display: grid;
    column-gap: 12px;
    grid-template-columns: 1fr 1fr;
    padding: 12px 0;
}

.modal .benefits_features_files .list .panel_list button {
    width: 100%;
    height: 30px;
    color: #fff;

    font-family: 'Jura', sans-serif;
    font-weight: 800;
    font-size: 16px;
}

.modal .benefits_features_files .list .panel_list button:hover {
    opacity: .6;
}

.modal .benefits_features_files .list .panel_list .add {
    background-color: #90ABFF;
}

.modal .benefits_features_files .list .panel_list .del {
    background-color: #d41217bb;
}

/* ------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------- */

.modal .benefits_features_files .input_files {
    width: calc(50% - 160px);
    
    display: flex;
    row-gap: 4px;
    flex-direction: column;
}