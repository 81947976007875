.sect_verif_products .slider_img {
    user-select: none;
    min-width: 220px;
    width: 220px;
    row-gap: 20px;

    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-right: 20px;
}

.sect_verif_products .slider_img .view {
    width: 100%;
    height: 220px;
    overflow: hidden;
    border-radius: 4px;
}

.sect_verif_products .slider_img img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.sect_verif_products .slider_img .panel_slider {
    max-width: 100%;
    overflow-x: auto;

    scrollbar-width: thin;
    scrollbar-color: #0467AB90 #44444415;

    display: flex;
    column-gap: 7px;
    padding-bottom: 10px;
}

.sect_verif_products .slider_img .panel_slider button {
    width: 75px;
    min-width: 75px;
    height: 50px;

    cursor: pointer;
    overflow: hidden;

    border-radius: 2px;
    border: solid 1.5px #44444450;
}

.sect_verif_products .slider_img .panel_slider .actif {
    border: solid 2px #0467AB99;    
}