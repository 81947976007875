.sect_verif_achievments {
    width: 100%;
    
    display: flex;
    flex-wrap: wrap;

    row-gap: 30px;
    column-gap: 20px;

    box-shadow: 0 0 8px 30px #80808015;
    background-color: #80808015;
    padding: 5px 20px 20px;
}

.sect_verif_achievments li {
    width: calc(20% - 5px);
    padding: 8px;

    cursor: pointer;
    transition: .2s;

    display: flex;
    row-gap: 8px;
    align-self: flex-start;
    flex-direction: column;

    box-shadow: 0 2px 6px #44444414;
    background-color: #fff;
    border-radius: 4px;
}

.sect_verif_achievments li:hover {
    opacity: .7;
}

.sect_verif_achievments li .container-img {
    width: 100%;
    height: 150px;
    display: flex;
    justify-content: center;
    background-color: #0467ab33;
    border-radius: 4px 4px 0 0;
}

.sect_verif_achievments li img {
    width: 100%;
    height: 100%;
    max-width: 150px;
    object-fit: contain;
}

.sect_verif_achievments li p {
    font-family: 'Manrope', sans-serif;
    font-weight: 600;
    font-size: 16px;
    color: #444;
}

.mdl_show_achievment {
    width: 100%;
    height: calc(100vh - 70px);

    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #0467AB40;

    position: fixed;
    z-index: 500;
    left: 0;
    top: 0;
}

.mdl_show_achievment .content {
    width: 700px;

    display: flex;
    align-items: center;
    flex-direction: column;

    background-color: #fff;
    border-radius: 6px;
    
    position: relative;
    padding: 8px;
}

.mdl_show_achievment .slider_achievment {
    width: 100%;
    height: 295px;

    display: flex;
    align-items: center;
    justify-content: center;
    
    box-shadow: inset 0 0 6px #00000010;
    background-color: #0467AB40;
    position: relative;
    overflow: hidden;
}

.mdl_show_achievment .slider_achievment img {
    width: 45%;
    height: 80%;
    object-fit: contain;
}

.mdl_show_achievment .slider_achievment .arrow {
    font-family: 'Dosis', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 70px;

    transition: all .2s;
    user-select: none;
    color: #ffffff;
    cursor: pointer;

    transform: translateY(-50%);
    position: absolute;
    top: 45%;
}

.mdl_show_achievment .slider_achievment .arrow:hover {
    opacity: .5;
}

.mdl_show_achievment .slider_achievment .arrow_left {
    left: 20px;
}

.mdl_show_achievment .slider_achievment .arrow_right {
    right: 20px;
}

.mdl_show_achievment .descrip {
    display: flex;
    row-gap: 20px;
    flex-direction: column;
    padding: 10px 15px;
}

.mdl_show_achievment .descrip h2 {
    margin-top: 5px;
    color: #0467AB;
    font-family: 'Manrope', sans-serif;
    font-weight: 500;
    font-size: 24px;
}

.mdl_show_achievment .descrip p {
    max-height: 220px;
    overflow-y: auto;

    padding-right: 10px;
    scrollbar-width: thin;
    scrollbar-color: #0467AB90 #44444415;

    font-family: 'Manrope', sans-serif;
    font-weight: 500;
    font-size: 16px;
    color: #444;
}

.mdl_show_achievment .btn_close {
    width: 32px;
    height: 32px;

    display: flex;
    align-items: center;
    justify-content: center;
    
    border-radius: 10px;
    border: solid 2px #80808088;
    
    background-color: #fff;
    color: #808080;
    font-size: 18px;
    
    position: absolute;
    z-index: 1;

    right: 22px;
    top: 18px;

    transition: all .25s;
    opacity: .4;
}

.mdl_show_achievment .btn_close:hover {
    border: solid 2px #D4121798;
    color: #D41217a8;
    opacity: .9;
}

.mdl_show_achievment .btn_valid {
    width: 120px;
    height: 38px;
    margin: 14px 0 20px;

    border-radius: 2px;
    transition: all .3s;
    background-color: #90ABFF;

    font-family: 'Manrope', sans-serif;
    font-weight: 600;
    font-size: 18px;
    color: #fff;
}

.mdl_show_achievment .btn_valid:hover {
    opacity: .5;
}