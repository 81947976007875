.modal_products h1 {
    margin-top: 50px;
}

.modal_products .products_list_order {
    width: 100%;
    max-width: 1290px;

    height: 100%;
    max-height: 550px;

    row-gap: 20px;
    column-gap: 15px;

    display: grid;
    align-items: flex-start;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
    
    overflow-y: auto;
    scrollbar-width: thin;
    scrollbar-color: #90ABFF #44444415;

    background-color: #f4f4f4;
    box-shadow: 0 0 14px 14px #f4f4f4;
    
    margin: 20px auto 0;
    padding: 25px 40px;
}

.modal_products .products_list_order .onglet_return_product img {
    width: 50%;
}

.modal_products .products_list_order .ctn_onglet_product {
    position: relative;
}

.modal_products .products_list_order .onglet_products {
    width: 100%;
    height: 100%;
    min-height: 240px;
    overflow: hidden;

    display: flex;
    flex-direction: column;

    padding: 5px 5px 10px;
    transition: .25s;
    cursor: pointer;

    border-radius: 3px;
    background-color: white;
    box-shadow: 0 0 8px #22222212;    
}

.modal_products .products_list_order .onglet_products:hover {
    opacity: .7;    
}

.modal_products .products_list_order .preview {
    width: 100%;
    height: 200px;  
}

.modal_products .products_list_order img {
    width: 100%;
    height: 100%;
    object-fit: contain;    
}

.modal_products .products_list_order span {
    width: 100%;
    max-height: 40px;

    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;

    overflow: hidden;
    text-align: left;
    text-overflow: ellipsis;
    align-items: flex-start;

    color: #0467ab;
    padding: 0 5px;

    font-family: 'Manrope', sans-serif;
    font-weight: 600;
    font-size: 14px;   
}

.modal_products .products_list_order .btn_next,
.modal_products .products_list_order .btn_prev {
    transform: translateY(-50%);
    position: absolute;
    z-index: 10;
    top: 45%;

    width: 30px;
    height: 30px;
    
    border-radius: 15px;
    font-size: 16px;
    opacity: 0;

    box-shadow: 0 0 0 2px #fff;
    background-color: #90ABFF;
    transition: all .2s;
    color: #fff;
}

.modal_products .ctn_onglet_product:hover .btn_next,
.modal_products .ctn_onglet_product:hover .btn_prev {
    opacity: .95;
}

.modal_products .products_list_order .btn_next:hover,
.modal_products .products_list_order .btn_prev:hover {
    opacity: .6;
}

.modal_products .products_list_order .btn_next {
    right: 6px;
}

.modal_products .products_list_order .btn_prev {
    left: 6px;
}