.modal_verif {
    position: relative;
}

.modal_verif h1 {
    margin-bottom: 60px;
}

.modal_verif .panel_verif {
    /* border: solid 1px red; */
    width: 100%;
    display: flex;
    justify-content: space-between;

    padding: 0 10% 0 7.5%;

    position: absolute;
    top: 44px;
    left: 0;
}

.modal_verif .next_or_previous {
    width: 30%;
    display: flex;
    justify-content: space-between;
}

.modal_verif .panel_verif button {
    width: 120px;
    height: 38px;

    border-radius: 2px;
    transition: all .3s;
    background-color: #90ABFF;

    font-family: 'Manrope', sans-serif;
    font-weight: 600;
    font-size: 18px;
    color: #fff;
}

.modal_verif .panel_verif button:hover {
    opacity: .5;
}

.modal_verif .panel_verif .red {
    background-color: #f3666b;
    color: #fff;
}