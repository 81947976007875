.sect_verif_categories {
    display: flex;
    flex-wrap: wrap;

    row-gap: 40px;
    column-gap: 40px;
    justify-content: space-between;

    box-shadow: 0 0 8px 30px #80808015;
    background-color: #80808015;
    padding: 5px 20px 20px;
}

.sect_verif_categories .onglet_home {
    width: 100%;

    min-width: 210px;
    max-width: 242px;

    row-gap: 8px;
    cursor: pointer;

    display: flex;
    flex-direction: column;
    margin: auto;
}

.sect_verif_categories .onglet_home .preview {
    width: 100%;
    height: 150px;
    transition: .25s;

    display: flex;
    align-items: center;
    justify-content: center;

    border-radius: 3px;
    background-color: #0467ab4d;
    box-shadow: 0 0 6px #00000025;
}

.sect_verif_categories .onglet_home .preview:hover {
    background-color: #0467ab3d;
}

.sect_verif_categories .onglet_home img {
    width: 120px;
    height: 100px;
    object-fit: contain;
}

.sect_verif_categories .onglet_home span {
    display: flex;
    text-align: left;
    align-items: flex-start;

    color: #1e1e1e;
    padding: 0 5px;

    font-family: 'Manrope', sans-serif;
    font-weight: 600;
    font-size: 14px;
}

.sect_verif_categories .description {
    width: 100%;
    row-gap: 15px;

    display: flex;
    align-items: center;
    flex-direction: column;

    background-color: #0467ab66;
    padding: 40px 25px 80px;
    position: relative;
}

.sect_verif_categories .description h3 {
    width: 100%;
    max-width: 1290px;

    color: white;
    text-shadow: 0 0 2px #00000025;

    font-family: 'Roboto', sans-serif;
    font-weight: 700;
    font-size: 30px;

}

.sect_verif_categories .description p {
    width: 100%;
    max-width: 1290px;

    padding-left: 3px;
    margin-bottom: 25px;

    color: white;
    text-shadow: 0 0 2px #00000025;

    font-family: 'Manrope', sans-serif;
    font-weight: 500;
    font-size: 18px;
}

.sect_verif_categories .btn_switch,
.sect_verif_categories .btn_switch_actif {
    width: 50px;
    height: 24px;

    overflow: hidden;
    border-radius: 12px;
    background-color: #ffffff80;

    position: absolute;
    right: 50px;
    top: 48px;
}

.sect_verif_categories .btn_switch::before,
.sect_verif_categories .btn_switch_actif::before {
    width: 50px;
    height: 24px;

    content: '';
    display: block;

    border-radius: 12px;
    background-color: #0467abb8;
    
    transform: translate(-27px, -12px);
    position: absolute;
}

.sect_verif_categories .btn_switch::before {
    animation: disable .2s 0s 1 linear forwards;
}

@keyframes disable {
    0% {
        transform: translate(-27px, -12px);
    }
    100% {
        transform: translate(0, -12px);
    }
}

.sect_verif_categories .btn_switch_actif::before {
    animation: enable .2s 0s 1 linear forwards;
    transform: translate(0, -12px);
}

@keyframes enable {
    0% {
        transform: translate(0, -12px);
    }
    100% {
        transform: translate(-27px, -12px);
    }
}

.sect_verif_categories .btn_switch i,
.sect_verif_categories .btn_switch_actif i {
    width: 24px;
    height: 24px;
    display: block;

    border-radius: 12px;
    background-color: #fff;
    border: solid 1px #80808050;

    position: absolute;
    top: 0;
    z-index: 1;
}

.sect_verif_categories .btn_switch i {
    animation: dotDisable .2s 0s 1 linear forwards;
    transform: translateX(26px);
}

@keyframes dotDisable {
    0% {
        transform: translateX(0);
    }
    100% {
        transform: translateX(26px);
    }
}

.sect_verif_categories .btn_switch_actif i {
    animation: dotEnable .2s 0s 1 linear forwards;
    transform: translateX(0);
}

@keyframes dotEnable {
    0% {
        transform: translateX(26px);
    }
    100% {
        transform: translateX(0);
    }
}

.sect_verif_categories .description .main_menu_cat {
    width: 100%;
    max-width: 1290px;

    row-gap: 20px;
    column-gap: 30px;

    display: grid;
    padding: 25px 0;
    grid-template-columns: 1fr 1fr 1fr 1fr;
}

.sect_verif_categories .description .main_menu_cat button {
    width: 100%;
    height: 100%;

    cursor: pointer;
    transition: .25s;

    row-gap: 8px;
    display: flex;
    flex-direction: column;

    background-color: #ffffff;
    box-shadow: 0 0 6px #00000015;
    border-radius: 4px;
    padding: 8px;
}

.sect_verif_categories .description .main_menu_cat button:hover {
    opacity: .6;
}

.sect_verif_categories .description .main_menu_cat .preview {
    width: 100%;
    height: 150px;

    display: flex;
    align-items: center;
    justify-content: center;

    border-radius: 3px;
    background-color: #0467ab40;
}

.sect_verif_categories .description .main_menu_cat img {
    width: 120px;
    height: 100px;
    object-fit: contain;
}

.sect_verif_categories .description .main_menu_cat span {
    width: 100%;

    display: flex;
    text-align: left;
    align-items: flex-start;

    text-shadow: 0 0 2px #00000025;
    color: #444444;
    padding: 0 5px;

    font-family: 'Manrope', sans-serif;
    font-weight: 600;
    font-size: 14px;
}

.sect_verif_categories .description .main_menu_prod {
    width: 100%;
    max-width: 1290px;

    row-gap: 20px;
    column-gap: 15px;

    display: grid;
    padding: 15px 5px;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
}

.sect_verif_categories .description .main_menu_prod button {
    width: 100%;
    height: 100%;
    min-height: 240px;
    overflow: hidden;

    display: flex;
    flex-direction: column;

    padding: 5px 5px 10px;
    cursor: pointer;
    transition: .25s;

    border-radius: 3px;
    background-color: white;
    box-shadow: 0 0 8px #ffffff50;
}

.sect_verif_categories .description .main_menu_prod button:hover {
    opacity: .7;
}

.sect_verif_categories .description .main_menu_prod .preview {
    width: 100%;
    height: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.sect_verif_categories .description .main_menu_prod img {
    width: 80%;
    height: 80%;
    object-fit: contain;
}

.sect_verif_categories .description .main_menu_prod span {
    width: 100%;
    max-height: 40px;

    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;

    overflow: hidden;
    text-align: left;
    text-overflow: ellipsis;
    align-items: flex-start;

    color: #0467ab;
    padding: 0 5px;

    font-family: 'Manrope', sans-serif;
    font-weight: 600;
    font-size: 14px;
}