.name_and_text {
    row-gap: 25px;
    width: calc(50% - 40px);

    display: flex;
    flex-direction: column;
}

.name_and_text .input_text,
.textarea {
    width: 100%;
    max-height: 360px;
    row-gap: 4px;
    display: flex;
    flex-direction: column;
}

.textarea {
    height: 100%;
    min-height: 298px;
}

.name_and_text .input_text label,
.textarea label {
    font-family: 'Jaldi', sans-serif;
    font-weight: 600;
    font-size: 16px;
    color: #444;
    opacity: .9;
}

.name_and_text .input_text input,
.textarea textarea {
    font-family: 'Barlow', sans-serif;
    font-weight: 600;
    font-size: 14px;

    background-color: #90ABFF15;
    border: solid 1px #14141410;
    padding: 10px 14px;
}

.name_and_text .input_text input::placeholder,
.textarea textarea::placeholder {
    color: #14141466;
}

.name_and_text .input_text input:focus,
.textarea textarea:focus {
    border: solid 1px #90ABFF;
}

.name_and_text .input_text input {
    height: 36px;
}

.textarea textarea {
    height: calc(100%);
    resize: none;
}