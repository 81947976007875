.modal_create_services .flyers_video {
    display: flex;
    column-gap: 80px;
    justify-content: center;
}

.modal_create_services h2 {
    font-family: 'Jaldi', sans-serif;
    font-weight: 600;
    font-size: 16px;
    color: #444;
}

.modal_create_services .input_files {
    width: calc(50% - 160px);
    
    display: flex;
    row-gap: 4px;
    flex-direction: column;
}

.modal_create_services .input_video {
    width: 40%;
}

.modal_create_services .input_video .inp_video {
    width: 100%;

    font-family: 'Barlow', sans-serif;
    font-weight: 600;
    font-size: 14px;

    background-color: #90ABFF15;
    border: solid 1px #14141410;
    padding: 10px 14px;
}

.modal_create_services .inp_video::placeholder {
    color: #14141466;
}

.modal_create_services .inp_video:focus {
    border: solid 1px #90ABFF;
}

.modal_create_services .inp_video {
    height: 36px;
}