.sect_associated {
    position: relative;
    margin-bottom: 40px;
}

.sect_associated h2 {
    font-family: 'Jaldi', sans-serif;
    font-weight: 600;
    font-size: 16px;
    color: #444;
}

.sect_associated .select_list {
    width: calc(100% - 40px);

    overflow-y: auto;
    scrollbar-width: thin;
    scrollbar-color: #90ABFF #44444415;
    
    gap: 24px;
    display: grid;
    padding: 15px;
    grid-template-columns: 1fr 1fr 1fr 1fr;

    border: solid 2px #90ABFF;
    background-color: #90ABFF30;
}

.sect_associated li button {
    width: 100%;
    height: 44px;

    display: flex;
    align-items: center;

    background-color: #ffffff;
    padding: 0 14px;
}

.sect_associated .first button {
    background-color: #90ABFF;
    color: #fff;
}

.sect_associated .product button {
    background-color: #7DCFE299;
}

.sect_associated li p {
    width: 100%;
    height: 100%;

    display: flex;
    align-items: center;
    justify-content: space-between;

    font-family: 'Glory', sans-serif;
    font-weight: 500;
    font-size: 15px;

    line-height: 1.1rem;
    color: #141414bb;
    text-align: left;
}

.sect_associated .first p {
    color: #fff;
}

.sect_associated li i {
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;

    background-color: #fff;
    border: solid 1px #14141425;

    font-family: 'Dosis', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 22px;
}

.sect_associated li .no_value {
    width: 250px;
    display: flex;
    justify-content: center;

    opacity: .4;
    color: #90ABFF;
    font-family: 'Dosis', sans-serif;
    font-weight: 600;
    font-size: 32px;

    transform: translate(-50%, -50%);
    position: absolute;
    left: 50%;
    top: 50%;
}

.sect_associated .edit_category {
    width: 250px;
    height: 40px;

    display: flex;
    align-items: center;
    justify-content: center;
    
    font-family: 'Dosis', sans-serif;
    font-weight: 600;
    font-size: 20px;

    background-color: #90ABFFbb;
    margin: 80px auto;
    color: #fff;
}