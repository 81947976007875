.modal .inp_file {
    width: 100%;
    height: 39px;
    display: flex;
    
    border: dashed 2px #90ABFF88;
    background-color: #90ABFF15;
    border-radius: 20px;

    transition: all .3s;
    position: relative;
}

.modal .inp_file:hover {
    opacity: .5;
}

.modal .inp_file input {
    width: 100%;
    height: 100%;
    
    opacity: 0;
    cursor: pointer;

    position: absolute;
    left: 0;
    top: 0;
}

.modal .inp_file p {
    width: 100%;
    height: 100%;

    display: flex;
    column-gap: 12px;
    align-items: center;
    justify-content: center;

    color: #141414a0;
    padding: 0 15px;

    font-family: 'Itim', sans-serif;
    font-weight: 500;
    font-size: 16px;
}

.modal .inp_file span {
    
    font-family: 'Itim', sans-serif;
    font-weight: 500;
    font-size: 14px;
    color: #14141470;
}

.modal .input_files .files_list {
    width: 100%;
    max-height: 355px;

    display: flex;
    flex-direction: column;
    
    overflow-y: auto;
    scrollbar-width: thin;
    scrollbar-color: #90ABFF #44444415;

    padding: 8px 0;
    row-gap: 12px;
}

.modal .input_files .files_list li {
    width: calc(100% - 30px);
    height: 39px;

    display: flex;
    align-items: center;
    justify-content: space-between;
    
    border: solid 1px #14141415;
    background-color: #90ABFF15;
    user-select: none;
    
    padding: 0 5px 0 15px;
    margin: 0 15px;
}

.modal .input_files .files_list li p {
    width: 300px;
    height: 39px;

    display: flex;
    align-items: center;
    color: #141414bb;
    
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    
    font-family: 'Itim', sans-serif;
    font-weight: 500;
    font-size: 16px;
}

.modal .files_list .panel_input_file {
    height: 33px;
    column-gap: 4px;
    
    opacity: 0;
    transition: .15s;

    display: flex;
    align-items: center;
    justify-content: center;

}

.modal .files_list li:hover .panel_input_file {
    opacity: 1;
}

.modal .files_list .panel_input_file button {
    width: 25px;
    height: 100%;
}

.modal .files_list .panel_input_file i,
.modal .files_list .panel_input_file img {
    width: 100%;
    height: 100%;

    font-size: 18px;
    color: #90ABFF;
    object-fit: contain;
    transition: all .2s;

    display: flex;
    align-items: center;
    justify-content: center;
}

.modal .files_list .panel_input_file img {
    height: 75%;
    padding-top: 2px;
}

.modal .files_list .panel_input_file i:hover,
.modal .files_list .panel_input_file img:hover {
    opacity: .4;
}

.modal .files_list .panel_input_file .fa-chevron-up {
    padding-top: 3px;
}

.modal .files_list .panel_input_file .fa-chevron-down {
    padding-bottom: 1px;
}

/* .modal .input_files .files_list .btn_del_file {
    width: 30px;
    height: 30px;
    
    display: none;
    align-items: center;
    justify-content: center;

    box-shadow: 0 0 2px #14141415;
    border: solid 1px #14141415;
    background-color: #fff;
    border-radius: 5px;

    cursor: pointer;
    padding: 5px;
}

.modal .input_files .files_list li:hover .btn_del_file {
    display: flex;
}

.modal .input_files .files_list .btn_del_file img {
    width: 100%;
    height: 100%;
} */