.mdl_msg_of_verif {
    width: 100%;
    height: 100%;

    display: flex;
    align-items: center;
    justify-content: center;

    padding-bottom: 70px;
    background-color: #90ABFF30;

    position: fixed;
    z-index: 1000;
    left: 0;
    top: 0;
}

.mdl_msg_of_verif .content {
    display: flex;
    row-gap: 20px;

    align-items: center;
    flex-direction: column;
    
    box-shadow: 0 0 8px 8px #90ABFFee;
    background-color: #90ABFFee;
    padding: 40px 100px 45px;
}

.mdl_msg_of_verif span {
    display: flex;
    column-gap: 12px;
    align-items: center;

    text-shadow: 0 0 1px #00000055;
    color: #fff;
    
    font-family: 'Itim', sans-serif;
    font-weight: 400;
    font-size: 36px;
}

.mdl_msg_of_verif p {
    width: 380px;
    text-align: center;
    text-shadow: 0 0 1px #00000022;
    font-family: 'Glory', sans-serif;
    font-weight: 500;
    font-size: 22px;
    color: #fff;
}

.mdl_msg_of_verif .panel {
    display: flex;
    column-gap: 100px;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
}

.mdl_msg_of_verif button {
    width: 100px;
    height: 38px;

    border-radius: 2px;
    transition: all .3s;
    border: solid 2px #fff;

    font-family: 'Manrope', sans-serif;
    font-weight: 600;
    font-size: 18px;
    color: #fff;
}

.mdl_msg_of_verif button:hover {
    background-color: #fff;
    color: #90ABFF;
}

.mdl_msg_of_verif .red:hover {
    border: solid 2px #f3666b;
    background-color: #f3666b;
    color: #fff;
}