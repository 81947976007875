.input_list {
    width: 100%;
    position: relative;
}

.input_list input {
    width: calc(100% - 8px);
    font-family: 'Barlow', sans-serif;
    font-weight: 600;
    font-size: 14px;

    background-color: #90ABFF15;
    border: solid 1px #14141410;
    padding: 10px 14px;
}

.input_list input::placeholder {
    color: #14141466;
}

.input_list input:focus {
    border: solid 1px #90ABFF;
}

.input_list .panel_liste {
    width: 28px;
    height: 39px;

    opacity: 0;
    transition: .2s;

    display: flex;
    flex-direction: column;

    position: absolute;
    right: 8px;
    top: 0;
}

.input_list:hover .panel_liste {
    opacity: 1;
}

.input_list .panel_liste .btn_change_pos {
    width: 100%;
    height: 50%;
    max-height: 50%;

    background-color: #90ABFF88;
}

.input_list .panel_liste i {
    height: 100%;
    font-size: 14px;
    color: #fff;
}

.input_list .panel_liste .fa-chevron-up {
    margin-top: 3px;
}