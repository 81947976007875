*,
::after,
::before {
    margin: 0;
    padding: 0;

    outline: none;
    box-sizing: border-box;
}

body {
    background-color: #90ABFF;
    position: relative;
    overflow-x: hidden;
}

a {
    font-family: "Roboto", sans-serif;
    text-decoration: none;
    font-size: 1rem;
    color: black;
}

button {
    font-family: "Roboto", sans-serif;
    background-color: transparent;
    font-size: 1rem;
    cursor: pointer;
    border: none;
}

input,
textarea {
    border: none;
    resize: none;
    background-color: transparent;
}

ul {
    list-style: none;
}

/* ----------------------------------------------------------- */
/*                                                             */
/* ----------------------------------------------------------- */

.page {
    display: flex;
    position: relative;
    flex-direction: column;

    width: calc(100% - 200px);
    height: calc(100vh - 70px);
    transform: translate(200px, 70px);

    box-shadow: inset 0 0 8px #14141488;
    background-color: #f8f8f8;
    border-radius: 10px 0 0 0;
    padding: 40px 60px 0;
}

.modal {
    width: 100%;
    height: calc(100% - 60px);

    background-color: #fff;
    padding: 40px 70px 30px;

    border: solid 1px #14141410;
    border-top: none;
}

.modal h1 {
    width: 100%;
    height: 40px;

    color: #90ABFF;
    text-align: center;

    font-family: "Itim", sans-serif;
    font-weight: 400;
    font-size: 32px;
}

.modal form {
    width: 100%;
    height: calc(100% - 60px);

    overflow-y: auto;
    scrollbar-width: thin;
    scrollbar-color: #90ABFF #44444415;
    
    padding-bottom: 20px;
    margin-top: 50px;
    row-gap: 40px;

    display: flex;
    flex-direction: column;
}

.modal form .sect_name_text_images {
    display: flex;
    column-gap: 40px;
}

.modal .panel {
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 25%;
}

.modal form .btn_next,
.modal .btn_update,
.modal .btn_return,
.modal .btn_submit {
    display: flex;
    column-gap: 6px;
    align-items: center;    

    font-family: "Itim", sans-serif;
    font-weight: 400;
    font-size: 20px;

    text-shadow: 0 0 1px #14141415;
    transition: all .25s;

    background-color: #90ABFF;
    color: #fff;

    margin: 8px 0 24px;
    padding: 8px 24px;
}

.modal form .btn_next {
    margin: 8px auto 24px;
}

.modal .btn_return {
    background-color: #d41217b0;
}

.modal form .panel .btn_next:hover,
.modal form .panel .btn_update:hover,
.modal form .panel .btn_return:hover,
.modal form .panel .btn_submit:hover {
    opacity: .5;
}

/* ----------------------------------------------------------- */
/*                                                             */
/* ----------------------------------------------------------- */

.modal_verif {
    padding: 40px 26px 30px;
}

.modal_verif .sect_verif {
    width: 100%;
    height: calc(100vh - 350px);

    overflow-y: auto;
    scrollbar-width: thin;
    scrollbar-color: #90ABFF #44444415;
}

/* ----------------------------------------------------------- */
/*                                                             */
/* ----------------------------------------------------------- */

.modal_show {
    position: relative;
}

.modal_show .categories_list_order {
    width: 100%;
    max-height: 82%;
    max-width: 1290px;

    gap: 20px;
    margin: 45px auto 0;
    padding-right: 15px;

    overflow-y: auto;
    scrollbar-width: thin;
    scrollbar-color: #90ABFF #44444415;

    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
}

.modal_show .ctn_onglet_category {
    position: relative;
}

.modal_show .ctn_onglet_category .btn_next,
.modal_show .ctn_onglet_category .btn_prev {
    transform: translateY(-50%);
    position: absolute;
    top: 39%;

    padding: 8px 5px;
    transition: all .2s;
    opacity: 0;
}

.modal_show .ctn_onglet_category:hover .btn_next,
.modal_show .ctn_onglet_category:hover .btn_prev {
    opacity: 1;
}

.modal_show .ctn_onglet_category:hover .btn_next:hover i,
.modal_show .ctn_onglet_category:hover .btn_prev:hover i {
    color: #90ABFF;
}

.modal_show .ctn_onglet_category .btn_next i,
.modal_show .ctn_onglet_category .btn_prev i {
    transition: all .2s;
    font-size: 30px;
    color: #fff;
}

.modal_show .ctn_onglet_category .btn_next {
    right: 10px;
}

.modal_show .ctn_onglet_category .btn_prev {
    left: 10px;
}

.modal_show .onglet_categories {
    width: 100%;
    cursor: pointer;

    row-gap: 8px;
    display: flex;
    flex-direction: column;
}

.modal_show .onglet_categories .preview {
    width: 100%;
    height: 150px;
    transition: .25s;

    display: flex;
    align-items: center;
    justify-content: center;

    border-radius: 3px;
    background-color: #0467ab4d;
    box-shadow: 0 0 6px #00000025;
}

.modal_show .onglet_categories .preview:hover {
    background-color: #0467ab3d;
}

.modal_show .onglet_categories img {
    width: 120px;
    height: 100px;
    object-fit: contain;
}

.modal_show .onglet_categories span {
    display: flex;
    text-align: left;
    align-items: flex-start;

    color: #1e1e1e;
    padding: 0 5px;

    font-family: 'Manrope', sans-serif;
    font-weight: 600;
    font-size: 14px;
}

.modal_show .onglet_return_category .onglet_categories img {
    height: 60px;
}

/* ----------------------------------------------------------- */
/*                                                             */
/* ----------------------------------------------------------- */



/* ----------------------------------------------------------- */
/*                                                             */
/* ----------------------------------------------------------- */

.invisible {
    visibility: hidden;
}