.inp_multi_images {
    width: calc(50% - 40px);

    row-gap: 4px;
    display: flex;
    flex-direction: column;
}

.inp_multi_images label {
    font-family: 'Jaldi', sans-serif;
    font-weight: 600;
    font-size: 16px;
    color: #444;
    opacity: .9;
}

.inp_multi_images .input_file {
    height: 100%;
    max-height: 270px;
    column-gap: 18px;
    
    display: flex;
    align-items: center;
    justify-content: center;

    padding-right: 15px;
    background-color: #90ABFF15;
    border: dashed 2px #90ABFF88;
    
    transition: all .3s;
    position: relative;
}

.inp_multi_images .input_file:hover {
    background-color: #90ABFF08;
    border: dashed 2px #90ABFFbb;
}

.inp_multi_images input {
    width: 100%;
    height: 100%;
    
    opacity: 0;
    cursor: pointer;

    position: absolute;
    z-index: 1;
    left: 0;
    top: 0;
}

.btn_del_image {
    width: 44px;
    height: 44px;
    padding: 7px;
    transition: .3s;

    border-radius: 22px;
    background-color: #fff;
    border: solid 1px #14141415;
    box-shadow: 0 0 2px #90ABFF88;

    position: absolute;
    z-index: 2;
    right: 24px;
    top: 24px;
}

.btn_del_image:hover {
    opacity: .3;
}

.btn_del_image img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.inp_multi_images .preview {
    height: 100%;
    transition: .3s;
    object-fit: contain;
}

.inp_multi_images .input_file:hover .preview {
    opacity: .5;
}

.inp_multi_images .icon {
    height: 65px;
    transition: .3s;
}

.inp_multi_images .input_file:hover .icon {
    opacity: .5;
}

.inp_multi_images .placeholder {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    transition: all .3s;
}

.inp_multi_images:hover .placeholder {
    opacity: .5;
}

.inp_multi_images span {
    font-family: 'Itim', sans-serif;
    font-weight: 500;
    font-size: 18px;
    color: #14141440;
}

.inp_multi_images p {
    font-family: 'Itim', sans-serif;
    font-weight: 500;
    font-size: 22px;
    color: #14141466;
}

.inp_multi_images .images_list {
    width: 100%;
    height: 90px;
    min-height: 90px;

    display: flex;
    overflow: hidden;
    padding: 0 115px;
    
    border: solid 2px #90ABFF60;
    margin-top: 24px;
    position: relative;
}

.inp_multi_images .images_list li {
    width: 115px;
    height: 100%;
    min-width: 115px;
    
    border-right: solid 2px #90ABFF60;
    transition: all .2s;
    cursor: pointer;

    position: relative;
}

.inp_multi_images .images_list .fas {
    width: 22px;
    height: 26px;

    display: flex;
    align-items: center;
    justify-content: center;
    
    background-color: #90ABFFb0;
    text-shadow: 0 0 2px #444;
    font-size: 14px;
    color: #fff;
    opacity: 0;

    transform: translateY(-50%);
    position: absolute;
    z-index: 1;
    top: 45%;
}

.inp_multi_images .images_list li:hover .fas {
    opacity: 1;
}

.inp_multi_images .images_list .fa-chevron-left {
    border-radius: 0 13px 13px 0;
    padding-right: 4px;
    left: 0;
}

.inp_multi_images .images_list .fa-chevron-right {
    border-radius: 13px 0 0 13px;
    padding-left: 4px;
    right: 0;
}

.inp_multi_images .images_list img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.inp_multi_images .images_list .prev,
.inp_multi_images .images_list .next {
    width: 115px;
    height: 100%;
    min-width: 115px;
    position: absolute;
    top: 0;
}

.inp_multi_images .images_list .prev {
    left: 0;
}

.inp_multi_images .images_list .next {
    right: 0;
}