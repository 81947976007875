.sect_verif_services {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    row-gap: 80px;
}

.sect_verif_services .player {
    padding: 20px;
}

.sect_verif_services .onglet {
    width: 200px;
    height: 253px;
    min-height: 240px;
    overflow: hidden;

    display: flex;
    flex-direction: column;

    margin: 70px auto;
    padding: 5px 5px 10px;

    cursor: pointer;
    transition: .25s;

    border-radius: 3px;
    background-color: white;
    box-shadow: 0 0 12px 50px #0467AB55;
}

.sect_verif_services .onglet:hover {
    opacity: .7;    
}

.sect_verif_services .onglet .preview {
    width: 100%;
    height: 200px;  
}

.sect_verif_services .onglet img {
    width: 100%;
    height: 100%;
    object-fit: contain;    
}

.sect_verif_services .onglet span {
    width: 100%;
    max-height: 40px;

    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;

    overflow: hidden;
    text-align: left;
    text-overflow: ellipsis;
    align-items: flex-start;

    color: #0467ab;
    padding: 0 5px;

    font-family: 'Manrope', sans-serif;
    font-weight: 600;
    font-size: 14px;   
}

.sect_verif_services .descrip_and_slider {
    width: 100%;
    display: flex;
    column-gap: 80px;

    box-shadow: 0 0 8px 30px #0467AB55;
    background-color: #0467AB55;
    padding: 40px 40px 80px;
}

.sect_verif_services .descrip_and_slider .descrip {
    height: 100%;
    max-height: 100%;
    width: calc(100% - 680px);
    row-gap: 22px;
    display: flex;
    flex-direction: column;
}

.sect_verif_services .descrip_and_slider .descrip h2 {
    color: #fff;
    font-family: 'Roboto', sans-serif;
    font-weight: 700;
    font-size: 30px;
    text-shadow: 0 0 1px #000000d0;
}

.sect_verif_services .descrip_and_slider .descrip p {
    max-height: 380px;

    overflow-y: auto;
    scrollbar-width: thin;
    scrollbar-color: #90ABFF #44444415;

    text-shadow: 0 0 1px #000000e0;
    font-family: 'Manrope', sans-serif;
    font-weight: 800;
    font-size: 18px;
    color: #fff;
}

.sect_verif_services .descrip_and_slider .descrip .btn_descr {
    height: 35px;
    display: flex;
    align-items: center;

    align-self: flex-start;
    justify-content: center;
    
    font-family: 'Roboto', sans-serif;
    font-weight: 500;
    font-size: 16px;

    color: #0467ABb0;
    background-color: #ffffff;
    box-shadow: 0 0 2px #80808088;
    border-radius: 3px;

    padding: 8px 15px;
    margin-top: 12px;

    transition: all .25s ease-out;
}

.sect_verif_services .flyers {
    row-gap: 8px;
    display: flex;
    flex-direction: column;
}

.sect_verif_services .flyers h4 {
    text-decoration: underline;
    text-underline-offset: 3px;
    text-decoration-thickness: 1px;
    text-shadow: 0 0 2px #808080;

    font-family: 'Manrope', sans-serif;
    font-weight: 700;
    font-size: 18px;
    color: #fff;
}

.sect_verif_services .flyers ul {
    height: 100%;
    max-height: 128px;

    overflow-y: auto;
    scrollbar-width: thin;
    scrollbar-color: #90ABFF #44444415;

    display: grid;
    row-gap: 8px;
    column-gap: 12px;
    grid-template-columns: 1fr 1fr;
}

.sect_verif_services .flyers li {

}

.sect_verif_services .flyers button {
    width: 100%;
    box-shadow: 0 0 4px #fff;
    background-color: #fff;
    color: #0467AB;

    padding: 6px 14px;
}