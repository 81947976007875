.modal_delete_categories {
    position: relative;
}

.modal_delete_categories .select_type_prod,
.modal_delete_categories .select_type_serv {
    width: 200px;
    height: 50px;
    
    color: #90ABFF;
    border: solid 2px #90ABFF;

    font-family: 'Itim', sans-serif;
    font-weight: 500;
    font-size: 24px;

    position: absolute;
    top: 85px;
}

.modal_delete_categories .select_type_prod {
    left: calc(25% - 185px);
}

.modal_delete_categories .select_type_serv {
    right: calc(25% - 185px);
}

.modal_delete_categories .select_type_prod:hover,
.modal_delete_categories .select_type_serv:hover {
    border: none;
    background-color: #90ABFF99;
    color: #fff;
}

.modal_delete_categories .actif {
    border: none;
    background-color: #90ABFFd0;
    color: #fff;
}