.modal_references {
    display: flex;
    row-gap: 16px;
    flex-direction: column;
    align-items: flex-start;
}

.modal_references h2 {
    width: 400px;
    padding: 0 4px;
    font-family: 'Jaldi', sans-serif;
    font-weight: 600;
    font-size: 18px;
    color: #444;
}

.modal_references .table_ref {
    max-width: 100%;
    
    overflow-y: auto;
    overflow-x: auto;
    scrollbar-width: thin;
    scrollbar-color: #90ABFF #44444415;

    display: flex;
    flex-direction: column;

    border-radius: 4px;
    box-sizing: border-box;
    border: solid 4px #90ABFFbb;
}

.modal_references .table_ref ul {
    width: 100%;
    display: flex;
}

.modal_references .table_ref li {
    width: 255px;
    min-width: 255px;
    display: flex;
    position: relative;
    box-sizing: border-box;
    border-bottom: solid 4px #90ABFFbb;
    border-right: solid 4px #90ABFFbb;
}

.modal_references .table_ref ul:last-child {
    border: none;
}

.modal_references .table_ref input,
.modal_references .table_ref .input_table {
    width: 100%;
    height: 40px;
    padding: 15px;

    display: flex;
    align-items: center;
    background-color: #90ABFF22;

    font-family: 'Manrope', sans-serif;
    font-weight: 600;
    font-size: 16px;
}

.modal_delete_references .table_ref .input_table {
    background-color: #d4121715;
}

.modal_references .table_ref .th_order {
    width: 76px;
    min-width: 76px;
}

.modal_references .table_ref .th_order button {
    width: 100%;
    display: flex;
    background-color: #90ABFF22;
}

.modal_references .table_ref .th_order i {
    width: 50%;
    height: 100%;

    display: flex;
    align-items: center;
    justify-content: center;

    transition: all .2s;
    color: #90ABFF;
    font-size: 20px;
}

.modal_references .table_ref .th_order .fa-chevron-up {
    padding-top: 2px;
}

.modal_references .table_ref .th_order .fa-chevron-up:hover {
    background-color: #d4121788;
    color: #fff;
}

.modal_references .table_ref .th_order .fa-chevron-down {
    padding-bottom: 1px;
}

.modal_references .table_ref .th_order .fa-chevron-down:hover {
    background-color: #0467ab88;
    color: #fff;
}

.modal_references .table_ref .input_schema {
    width: 100%;
    height: 40px;
    position: relative;
}

.modal_references .table_ref .input_schema input {
    width: 100%;
    height: 100%;

    opacity: 0;
    padding: unset;
    cursor: pointer;
    background-color: #fff;

    position: absolute;
    left: 0;
    top: 0;
}

.modal_references .table_ref .input_schema label {
    width: 100%;
    height: 100%;

    display: flex;
    align-items: center;
    justify-content: center;

    font-family: 'Roboto', sans-serif;
    font-weight: 500;
    font-size: 16px;
}

.modal_references .table_ref .li_add_column {
    width: unset;
    min-width: unset;
}

.modal_references .table_ref .btn_add_column {
    width: 50px;
    min-width: 50px;
    height: 40px;

    color: #fff;
    font-size: 22px;
    background-color: #90ABFFbb;
}

.modal_references .table_ref .btn_del_row {
    width: 30px;
    height: 30px;

    opacity: 0;
    padding: 4px;
    transition: all .3s;
    background-color: #fff;
    
    border: solid 1px #80808044;
    border-radius: 4px;

    transform: translateY(-50%);
    position: absolute;
    
    right: 12px;
    top: 50%;
}

.modal_references .table_ref li:hover .btn_del_row {
    opacity: 1;
}

.modal_references .table_ref img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.modal_references .panel_references {
    display: flex;
}

.modal_references .panel_references .btn_add_row {
    width: calc(76px + 510px);
    min-width: calc(76px + 510px);
    height: 50px;

    font-family: 'Manrope', sans-serif;
    font-weight: 600;
    font-size: 19px;
    
    background-color: #90ABFFbb;
    padding-bottom: 4px;
    color: #fff;
}

.modal_references .panel_references li {
    height: 100%;
    display: flex;
    align-items: center;

    background-color: #90ABFFbb;
    padding-bottom: 4px;
    border: none;
}

.modal_references .panel_references p {
    width: calc(100% - 60px);
    text-align: center;
    user-select: none;
    color: #fff;

    font-family: 'Manrope', sans-serif;
    font-weight: 600;
    font-size: 16px;
}

.modal_references .panel_references .fas {
    width: 30px;
    height: 30px;

    color: #fff;
    cursor: pointer;
    transition: .2s;

    display: flex;
    align-items: center;
    justify-content: center;
}

.modal_references .panel_references .fas:hover {
    opacity: .5;
}

.modal_references .panel_references .btn_add_column {
    width: 54px;
    height: 100%;
}

.modal_references .btn_validation {
    margin: 38px auto 26px;
    padding: 8px 22px;

    border-radius: 2px;
    transition: all .3s;
    border: solid 2px #fff;
    background-color: #90ABFF;

    font-family: 'Manrope', sans-serif;
    font-weight: 600;
    font-size: 18px;
    color: #fff;
}

.modal_references .btn_validation:hover {
    background-color: #fff;
    color: #90ABFF;
}