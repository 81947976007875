.modal_update_categories {
    position: relative;
}

.modal_update_categories .select_type_prod,
.modal_update_categories .select_type_serv {
    width: 200px;
    height: 50px;
    
    color: #90ABFF;
    border: solid 2px #90ABFF;

    font-family: 'Itim', sans-serif;
    font-weight: 500;
    font-size: 24px;

    position: absolute;
    top: 85px;
}

.modal_update_categories .select_type_prod {
    left: calc(25% - 185px);
}

.modal_update_categories .select_type_serv {
    right: calc(25% - 185px);
}

.modal_update_categories .select_type_prod:hover,
.modal_update_categories .select_type_serv:hover {
    border: none;
    background-color: #90ABFF99;
    color: #fff;
}

.modal_update_categories .actif {
    border: none;
    background-color: #90ABFFd0;
    color: #fff;
}

.modal_update_categories .input_img {
    width: calc(50% - 40px);
    height: 300px;

    display: flex;
    flex-direction: column;
}

.modal_update_categories .input_img label {
    font-family: 'Jaldi', sans-serif;
    font-weight: 600;
    font-size: 16px;
    color: #444;
    opacity: .9;
}

.modal_update_categories .input_img .input_file {
    height: 100%;
    column-gap: 18px;

    display: flex;
    align-items: center;
    justify-content: center;

    padding-right: 15px;
    background-color: #90ABFF15;
    border: dashed 2px #90ABFF88;

    transition: all .3s;
    position: relative;
}

.modal_update_categories .input_img .input_file:hover {
    background-color: #90ABFF08;
    border: dashed 2px #90ABFFbb;
}

.modal_update_categories .input_img input {
    width: 100%;
    height: 100%;

    opacity: 0;
    cursor: pointer;

    position: absolute;
    z-index: 1;
    left: 0;
    top: 0;
}

.modal_update_categories .btn_del_image {
    width: 44px;
    height: 44px;
    padding: 7px;
    transition: .3s;

    border-radius: 22px;
    background-color: #fff;
    border: solid 1px #14141415;
    box-shadow: 0 0 2px #90ABFF88;

    position: absolute;
    z-index: 2;
    right: 24px;
    top: 24px;
}

.modal_update_categories .btn_del_image:hover {
    opacity: .3;
}

.modal_update_categories .btn_del_image img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.modal_update_categories .input_img .preview {
    height: 100%;
    transition: .3s;
    object-fit: contain;
}

.modal_update_categories .input_img:hover .preview {
    opacity: .5;
}

.modal_update_categories .input_img .icon {
    height: 65px;
    transition: .3s;
}

.modal_update_categories .input_img:hover .icon {
    opacity: .5;
}

.modal_update_categories .input_img .placeholder {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    transition: all .3s;
}

.modal_update_categories .input_img:hover .placeholder {
    opacity: .5;
}

.modal_update_categories .input_img span {
    font-family: 'Itim', sans-serif;
    font-weight: 500;
    font-size: 18px;
    color: #14141440;
}

.modal_update_categories .input_img p {
    font-family: 'Itim', sans-serif;
    font-weight: 500;
    font-size: 22px;
    color: #14141466;
}