.modal_selector {
    width: 100%;
    display: flex;
}

.modal_selector li {
    width: 100%;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;

    cursor: pointer;
    padding: 13px 25px;
    transition: all .3s;
    
    font-family: 'Manrope', sans-serif;
    font-weight: 600;
    font-size: 16px;

    text-shadow: 0 0 1px #14141420;
    box-shadow: 0 0 2px #14141410;
    background-color: #eee;
    color: #90ABFF;
    
    border-radius: 15px 15px 0 0;
    border: solid 1px #14141405;
    border-bottom: none;
}

.modal_selector li:hover {
    background-color: #90ABFFbb;
    color: #fff;
}

.modal_selector .actif {
    border: solid 1px #14141408;
    background-color: #fff;
    border-bottom: none;
    color: #aaa;
}