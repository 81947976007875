.sect_verif_products {
    display: flex;
    row-gap: 80px;

    flex-wrap: wrap;
    justify-content: space-between;

    box-shadow: 0 0 8px 30px #80808015;
    background-color: #80808015;
    padding: 5px 20px 20px;
}

.sect_verif_products .onglet_page {
    width: 200px;
    height: 253px;
    min-height: 240px;
    overflow: hidden;

    display: flex;
    flex-direction: column;

    margin: 70px auto;
    padding: 5px 5px 10px;

    cursor: pointer;
    transition: .25s;

    border-radius: 3px;
    background-color: white;
    box-shadow: 0 0 12px 50px #0467AB55;
}

.sect_verif_products .onglet_page:hover {
    opacity: .7;    
}

.sect_verif_products .onglet_page .preview {
    width: 100%;
    height: 200px;  
}

.sect_verif_products .onglet_page img {
    width: 100%;
    height: 100%;
    object-fit: contain;    
}

.sect_verif_products .onglet_page span {
    width: 100%;
    max-height: 40px;

    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;

    overflow: hidden;
    text-align: left;
    text-overflow: ellipsis;
    align-items: flex-start;

    color: #0467ab;
    padding: 0 5px;

    font-family: 'Manrope', sans-serif;
    font-weight: 600;
    font-size: 14px;   
}

/* --------------------------------------------------------------------- */
/* --------------------------------------------------------------------- */

.sect_verif_products .features_and_benefits {
    width: 100%;
    padding: 40px 0 60px;
    background-color: #0467AB66;
}

.sect_verif_products .features_and_benefits .container {
    width: 100%;
    max-width: 1290px;
    
    display: grid;
    column-gap: 80px;
    grid-template-columns: 1fr 1fr;
    
    padding: 0 75px;
    margin: 0 auto;
}

.sect_verif_products .features_and_benefits ul {
    width: 100%;

    display: flex;
    flex-direction: column;
    
    row-gap: 8px;
    padding: 20px 40px 35px;

    box-shadow: 0 0 8px #00000025;
    background-color: white;
}

.sect_verif_products .features_and_benefits li {
    display: flex;
    column-gap: 10px;
    align-items: center;
}

.sect_verif_products .features_and_benefits .features li::before {
    content: '-';
}

.sect_verif_products .features_and_benefits .benefits li::before {
    content: '+';
    font-weight: 800;
}

.sect_verif_products .features_and_benefits li:first-child:before {
    content: unset;
}

.sect_verif_products .features_and_benefits h4 {
    margin-bottom: 5px;
    font-family: 'Roboto', sans-serif;
    font-size: 20px;
    color: #444;
    opacity: .9;
}

.sect_verif_products .features_and_benefits p {
    font-family: 'Manrope', sans-serif;
    font-weight: 600;
    font-size: 16px;
    color: #444;    
}

/* --------------------------------------------------------------------- */
/* --------------------------------------------------------------------- */

.sect_verif_products .dashboard {
    width: 100%;
    max-width: 1290px;
    margin: 0 auto;
    padding-bottom: 30px;
}

.sect_verif_products .dashboard .onglets {
    display: flex;
    column-gap: 15px;
    justify-content: center;
    padding-bottom: 20px;
}

.sect_verif_products .dashboard .onglets button {
    padding: 6px 50px 4px;
    transition: all .2s;

    color: #808080c0;
    border-radius: 15px;
    border: solid 2px #00000000;

    font-family: 'Manrope', sans-serif;
    font-weight: 700;
    font-size: 16px;
}

.sect_verif_products .dashboard .onglets button:hover {
    color: #808080d0;
    border: solid 2px #0467AB90;
    box-shadow: 0 0 4px #00000005;
    background-color: #ffffffb0;
}

.sect_verif_products .dashboard .onglets .actif {
    color: #fff;
    border: solid 2px #00000005;
    box-shadow: 0 0 4px #00000005;
    background-color: #0467AB90;
    /* background: radial-gradient(#0467AB90 45%, #0467AB35 89%, transparent 100%); */
}

/* --------------------------------------------------------------------- */
/* --------------------------------------------------------------------- */

.sect_verif_products .sect_descrip {
    min-height: 200px;
    display: flex;

    border-radius: 8px;
    border: solid 4px #0467AB90;
    box-shadow: 0 0 8px #00000020;

    background-color: #fff;
    padding: 40px;
}

.sect_verif_products .sect_descrip .container_descrip {
    max-width: 80%;
    display: flex;
    row-gap: 15px;
    flex-direction: column;
}

.sect_verif_products .sect_descrip h4 {
    font-family: 'Roboto', sans-serif;
    font-weight: 700;
    font-size: 24px;
    color: #444;
}

.sect_verif_products .sect_descrip .descrip {
    font-family: 'Manrope', sans-serif;
    font-weight: 500;
    font-size: 18px;
    color: #444; 
}

/* --------------------------------------------------------------------- */
/* --------------------------------------------------------------------- */

.sect_verif_products .sect_download {
    min-height: 200px;
    display: flex;

    border-radius: 8px;
    border: solid 4px #0467AB90;
    box-shadow: 0 0 8px #00000020;

    background-color: #fff;
    padding: 40px;
}

.sect_verif_products .sect_download .container_descrip {
    width: 100%;
    display: flex;
    row-gap: 15px;
    flex-direction: column;
}

.sect_verif_products .sect_download h4 {
    font-family: 'Roboto', sans-serif;
    font-weight: 700;
    font-size: 24px;
    color: #444;
}

.sect_verif_products .sect_download .download_list {
    row-gap: 4px;
    display: flex;
    flex-direction: column;
}

.sect_verif_products .sect_download .download_list .no-data {
    font-family: 'Manrope', sans-serif;
    font-weight: 800;
    font-size: 24px;

    color: #0467ab66;
    margin: 40px auto;
}

.sect_verif_products .sect_download button {
    font-family: 'Manrope', sans-serif;
    font-weight: 500;
    font-size: 18px;
    color: #444; 
}

.sect_verif_products .sect_download button:hover {
    color: #D41217d0; 
}

/* --------------------------------------------------------------------- */
/* --------------------------------------------------------------------- */

.sect_verif_products .prod_associated_list {
    width: 100%;
    max-width: 1290px;
    min-height: 200px;

    row-gap: 20px;
    column-gap: 30px;

    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    
    padding: 25px 0;
    position: relative;
}

.sect_verif_products .prod_associated_list .no-data {
    font-family: 'Manrope', sans-serif;
    font-weight: 800;
    font-size: 24px;

    color: #0467ab66;
    position: absolute;

    transform: translateX(-50%);
    left: 50%;
    top: 60px;
}

.sect_verif_products .prod_associated_list button {
    width: 100%;
    height: 100%;
    
    cursor: pointer;
    transition: .25s;

    row-gap: 8px;
    display: flex;
    flex-direction: column;

    background-color: #0467ab68;
    box-shadow: 0 0 6px #00000015;
    border-radius: 4px;
    padding: 8px;
}

.sect_verif_products .prod_associated_list button:hover {
    opacity: .8;
}

.sect_verif_products .prod_associated_list .preview {
    width: 100%;
    height: 150px;

    display: flex;
    align-items: center;
    justify-content: center;

    border-radius: 3px;
    background-color: #ffffff;
}

.sect_verif_products .prod_associated_list img {
    width: 120px;
    height: 100px;
    object-fit: contain;
}

.sect_verif_products .prod_associated_list span {
    width: 100%;

    display: flex;
    text-align: left;
    align-items: flex-start;

    text-shadow: 0 0 2px #00000025;
    color: #ffffff;
    padding: 0 5px;

    font-family: 'Manrope', sans-serif;
    font-weight: 600;
    font-size: 14px;
}